import React from "react";
import Box from "@material-ui/core/Box";

function CuddleYou() {
  return (
    <Box className="trackDescription">
      <p>
        This song was made in France by Marija. In this song she expresses her
        feelings towards and dreams about sofas of the world.{" "}
      </p>
    </Box>
  );
}

export default CuddleYou;
